.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }
  h1 {
    color: #0e0e0e;
    font-size: 7.5rem;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #0e0e0e;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;

      opacity: 0.6;
    }

    &::after {
      content: '</h1>';
      font-family: 'La Belle Aurore';
      color: #0e0e0e;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
      top: 60%;
    }

    img {
      width: 72px;
      margin-left: 20px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 5px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #0e0e0e;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #0e0e0e;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #e88607;
      border: 1px solid #e88607;
      color: #fff;
    }
  }

  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    .link-list {
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      a {
        text-decoration: none !important;
        color: #0e0e0e;

        &:hover {
          color: #e88607;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1::before,
  .home-page h1::after {
    display: none;
  }
  .home-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
    }
    .flat-button {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 125px;
    }

    // .logo-container {
    //     position: relative;
    //     width: 200px;
    //     height: auto;
    //     top: 50px;
    //     right: 0;
    //     box-sizing: border-box;
    //     margin: auto;
    //     left: 0;

    // .svg {
    //     position: absolute;
    //     top: auto;
    //     right: auto;
    //     bottom: auto;
    //     left: 0;
    //     margin: auto;
    // }
    // }
  }
}

@media screen and (max-width: 1200px) {
  .home-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 6rem;
    }
    .text-zone {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
