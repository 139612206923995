.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #0e0e0e;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;

    #canvas1 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

.container.about-page,
.container.contact-page,
.container.portfolio-page,
.container.skills-page {
    
    h1 {
        font-size: 70px;
        font-family: 'Coolvetica';
        color: #e88607;
        font-weight: 400;
        margin-top: 40px;
        position: relative;
        margin-bottom: 40px;
        left: 10px;


        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            opacity: 0.6;
            line-height: 18px;
            color: #0e0e0e;
        }

        &::after {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            font-size: 18px;
            line-height: 18px;
            position: absolute;
            left:-30px;
            bottom: -20px;
            margin-left: 20px;
            opacity:0.6;
            color: #0e0e0e;  
        }
    }
    h2 {
        font-size: 40px;
        font-family: 'Coolvetica';
        color: #e88607;
        font-weight: 300;
        margin-top: 40px;
        position: relative;
        margin-bottom: 40px;
        left: 10px;


        &::before {
            content: '<h2>';
            font-family: 'La Belle Aurore';
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            opacity: 0.6;
            line-height: 18px;
            color: #0e0e0e;
        }

        &::after {
            content: '<h2>';
            font-family: 'La Belle Aurore';
            font-size: 18px;
            line-height: 18px;
            position: absolute;
            left:-30px;
            bottom: -20px;
            margin-left: 20px;
            opacity:0.6;
            color: #0e0e0e;  
        }
    }
    p{
        font-size: 17px;
        color: #0e0e0e;
        font-family: sans-serif;
        font-weight: 400;
        min-width: fit-content;
        animation: pulse 1s;

        &:nth-last-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-last-of-type(2) {
            animation-delay: 1.2s;
        }
        &:nth-last-of-type(3) {
            animation-delay: 1.3s;
        }

        a{
            font-style: none;
            color: #e88607;
        }
    }
    .text-animate-hover {
        &:hover {
            color: #0e0e0e;
        }
        
    }
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 30%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer */
    }  
    .text-zone::-webkit-scrollbar {
        width: 0;
        display: none;
    }
    
}

.container.about-page {
    .text-zone {
        width: 40%;
        height: 70%;

        span {
            color: #e88607;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 1200px) {
    .page {
        position: initial;
    }
    .container {
        position: initial;
        height: auto;
        min-height: auto;

        &.about-page,
        &.contact-page,
        &.portfolio-page,
        &.skills-page {
            .text-zone {
                position: initial;
                transform: none;
                width: 100%;
                height: auto;
                display: block;
                padding: 20px;
                box-sizing: border-box;
            }
            
        }
        
    }
    .about-page {
        .stage-cube-cont {
            position: initial;
            width: 100%;
            height: 0%;
            overflow: visible;
        }
    }
    
}


